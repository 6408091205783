<template>
  <div class="page-container">
    <h-table
      :options="options"
      :columns="table.columns"
      :data="table.data"
      :loading="table.loading"
    >
    </h-table>
    <h-modal
      @on-visible-change="modalVisibleChange"
      @on-ok="submit"
      :width="420"
      v-model="modal.show"
      :title="modal.title"
    >
      <Form
        ref="form"
        :loading="modal.loading"
        :model="modal.model"
        :label-width="85"
        :rules="modal.rules"
      >
        <FormItem prop="name" label="公司类别">
          <Input v-model="modal.model.name"></Input>
        </FormItem>
        <FormItem prop="roleId" label="权限角色">
          <Select v-model="modal.model.roleId">
            <Option
              v-for="role in roles"
              :value="role.id"
              :key="role.id"
              :label="role.name"
            >
              <div style="font-size: 16px">{{ role.name }}</div>
              <div style="font-size: 12px; opacity: 0.8; padding-left: 10px">
                {{ role.tips }}
              </div>
            </Option>
          </Select>
        </FormItem>
      </Form>
    </h-modal>
  </div>
</template>
<script>
import { Poptip } from "view-design";

export default {
  data() {
    return {
      table: {
        columns: [
          {
            title: "序号",
            type: "index",
            width: 80,
          },
          {
            title: "公司类型",
            key: "name",
          },
          {
            title: "权限角色",
            key: "roleName",
          },
          {
            title: "操作",
            render: (h, { row }) => {
              return (
                <div class="table-option-cell">
                  <a onClick={() => this.edit(row)}>编辑</a>
                  <Poptip
                    confirm
                    transfer
                    title="确认删除该类别吗?"
                    onOn-ok={() => this.deleteRow(row)}
                  >
                    <a>删除</a>
                  </Poptip>
                </div>
              );
            },
          },
        ],
        data: [],
        loading: false,
      },
      modal: {
        show: false,
        title: "",
        loading: false,
        model: {
          id: "",
          name: "",
          roleId: "",
        },
        rules: {
          name: [
            {
              required: true,
              message: "请填写类别名称",
            },
          ],
          roleId: [
            {
              required: true,
              message: "请选择权限角色",
            },
          ],
        },
      },
      roles: [],
      page: {
        pageNo: 1,
        pageSize: 12,
        total: 0,
        onChange: this.pageChange,
      },
    };
  },
  computed: {
    options() {
      return {
        add: {
          text: "新增类型",
          ca: "company_type_add",
          callback: this.add,
        },
        page: this.page,
      };
    },
  },
  methods: {
    add() {
      this.modal.show = true;
      this.modal.title = "新增类型";
    },
    edit(row) {
      for (let key in this.modal.model) {
        this.modal.model[key] = row[key];
      }
      this.modal.show = true;
      this.modal.title = "编辑类型";
    },
    deleteRow(row) {
      this.$post(this.$api.company.typeDelete, {
        id: row.id,
      }).then((res) => {
        this.getTableData();
      });
    },
    submit() {
      this.$refs.form.validate((rs) => {
        if (!rs) return;
        let url = this.modal.model.id
          ? this.$api.company.typeEdit
          : this.$api.company.typeAdd;
        this.modal.loading = true;
        this.$post(url, {
          ...this.modal.model,
        })
          .then(() => {
            this.modal.show = false;
            this.getTableData();
          })
          .finally(() => {
            this.modal.loading = false;
          });
      });
    },
    modalVisibleChange(visible) {
      if (visible) return;
      this.modal.loading = false;
      this.modal.model.id = "";
      this.$refs.form.resetFields();
    },
    // 获取角色列表
    getRoles() {
      this.$post(this.$api.ROLE.LIST, {
        pageNo: 1,
        pageSize: 99999,
        roleType: "1",
      }).then((res) => {
        this.roles = res.list;
      });
    },
    pageChange(pageNo) {
      this.page.pageNo = pageNo;
      this.getTableData();
    },
    // 获取类型数据
    getTableData() {
      this.table.loading = true;
      this.$post(this.$api.company.typeList, {
        pageNo: this.page.pageNo,
        pageSize: this.page.pageSize,
      })
        .then((res) => {
          this.table.data = res.list;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {
    this.getRoles();
    this.getTableData();
  },
};
</script>
<style lang='less' scoped>
@import url("./index.less");
</style>